module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#F2EEEB","display":"standalone","icon":"./src/assets/images/favicon.png","name":"Valerio Oliva","orientation":"portrait","scope":"/","short_name":"Valerio Oliva","start_url":"/","theme_color":"#f39200","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"36ac814a3454882c9e846da0708ed851"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
