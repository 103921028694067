// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-kaindl-template-js": () => import("./../../../src/components/templates/kaindl-template.js" /* webpackChunkName: "component---src-components-templates-kaindl-template-js" */),
  "component---src-components-templates-materials-template-js": () => import("./../../../src/components/templates/materials-template.js" /* webpackChunkName: "component---src-components-templates-materials-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materiales-kaindl-js": () => import("./../../../src/pages/materiales/kaindl.js" /* webpackChunkName: "component---src-pages-materiales-kaindl-js" */),
  "component---src-pages-nuestra-empresa-js": () => import("./../../../src/pages/nuestra-empresa.js" /* webpackChunkName: "component---src-pages-nuestra-empresa-js" */),
  "component---src-pages-productos-materiales-js": () => import("./../../../src/pages/productos/materiales.js" /* webpackChunkName: "component---src-pages-productos-materiales-js" */),
  "component---src-pages-productos-vigas-laminadas-js": () => import("./../../../src/pages/productos/vigas-laminadas.js" /* webpackChunkName: "component---src-pages-productos-vigas-laminadas-js" */),
  "component---src-pages-productos-vigas-laminadas-productos-multilaminados-js": () => import("./../../../src/pages/productos/vigas-laminadas/productos-multilaminados.js" /* webpackChunkName: "component---src-pages-productos-vigas-laminadas-productos-multilaminados-js" */)
}

